import type { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Global, css } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import colors from '@/design/theme/colors';
import { featureControl } from '@/feature/toggle';
import { PublicLayout } from '@/layouts/public';
import SosCallDownloadRTL from '@/modules/ServiceDesk/SosCall/Download/RTL/SosCallDownload';
import SosCallAndroidLauncher from '@/modules/ServiceDesk/SosCall/Download/SosCallAndroidLauncher';
import SosCallAndroidLauncherRTL from '@/modules/ServiceDesk/SosCall/Download/SosCallAndroidLauncherRTL';
import SosCallDownload from '@/modules/ServiceDesk/SosCall/Download/SosCallDownload';
import getLocale from '@/utils/getLocale';
import getOS from '@/utils/getOS';

const FT_ANDROID_LAUNCHER = featureControl.getToggle('PCP_545__SOS_CALL_ANDROID_LAUNCHER');

const ServiceDeskSosCallDownloadPage = () => {
  const router = useRouter();
  const [isAndroid, setIsAndroid] = useState(false);
  const packageCode = router.query.code as string;

  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'he';

  useEffect(() => {
    const os = getOS();
    if (FT_ANDROID_LAUNCHER && os === 'Android') {
      setIsAndroid(true);
    }
  }, []);

  if (isAndroid) {
    return (
      <>
        <Global
          styles={css`
            body {
              background-color: ${colors.blue[10]};
            }
          `}
        />
        <PublicLayout>
          {isRTL ? <SosCallAndroidLauncherRTL packageCode={packageCode} /> : <SosCallAndroidLauncher packageCode={packageCode} />}
        </PublicLayout>
      </>
    );
  }

  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${colors.blue[10]};
          }
        `}
      />
      <PublicLayout>
        {isRTL ? <SosCallDownloadRTL packageCode={packageCode} /> : <SosCallDownload packageCode={packageCode} />}
      </PublicLayout>
    </>
  );
};

ServiceDeskSosCallDownloadPage.displayName = 'ServiceDeskSosCallDownloadPage';
ServiceDeskSosCallDownloadPage.skipAuth = true;

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  return {
    props: {
      ...(await serverSideTranslations(getLocale(req.headers['accept-language']), ['common', 'sos-package', 'sos-call'])),
      disableOsanoScript: true,
    },
  };
};

export default ServiceDeskSosCallDownloadPage;
