import { z } from 'zod';

export const sosCallLaunchURLDetailSchema = z.object({
  code: z.string(),
  category: z.union([z.literal(37), z.literal(38), z.literal(40), z.literal(43)]),
  team_code: z.string(),
  region: z.enum(['EU', 'US']),
});

export type SOSCallLaunchURLDetail = z.infer<typeof sosCallLaunchURLDetailSchema>;
