import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import Card from '@/components/BasicCard';
import FullPageLoading from '@/components/FullPageLoading';
import * as Logo from '@/components/Logo';
import { RippleButton, RippleDivider, RipplePlayStore, RippleTypography } from '@/design';
import { NumberTag } from '@/modules/Welcome/NumberTag';
import { getSosCallLaunchURLDetailService } from '@/services/teams/service_desk/call/getSOSCallLauncherURLDetail';
import getOS from '@/utils/getOS';
import { launchSosCallAndroidApp, launchSosPlayStore } from '@/utils/urlScheme';

export type SosCallAndroidLauncherProps = {
  packageCode: string;
};

const SosCallAndroidLauncher = ({ packageCode }: SosCallAndroidLauncherProps) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ['sosCall', 'AndroidLauncherDetail', packageCode],
    queryFn: async () => await getSosCallLaunchURLDetailService.execute(packageCode),
  });

  if (isLoading || !data) {
    return <FullPageLoading />;
  }

  const { category, code, team_code } = data;

  const handleLaunchApp = () => {
    launchSosCallAndroidApp(code, category, team_code);
  };

  const handleLaunchStore = () => {
    launchSosPlayStore(code, category);
  };

  const os = getOS();
  const isAndroid = os === 'Android';

  return (
    <Card maxWidth="640px" mb="15px" style={{ direction: 'rtl' }}>
      <Logo.Splashtop />
      <Box mt="1.5em" mb="1em">
        <Logo.SupportApp />
      </Box>

      <RippleTypography variant="heading05" color="dark.90" py="16px">
        Request support right from this mobile device
      </RippleTypography>
      <Box>
        <List as="ol" styleType="none" pt="48px" spacing="24px">
          <ListItem fontSize="14px" display="flex">
            <NumberTag>1</NumberTag>
            <Flex flexDirection="column" pr="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                {t('sos-package:downloadMobile.step1.line1.android')}
              </RippleTypography>
              {isAndroid && (
                <Box pt="12px">
                  <button onClick={handleLaunchStore}>
                    <RipplePlayStore />
                  </button>
                </Box>
              )}
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>2</NumberTag>
            <Flex flexDirection="column" pr="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                Launch the app to add this SOS Call
              </RippleTypography>
              <RippleTypography variant="body02" color="neutral.100">
                {t('sos-package:downloadMobile.step2.line2')}
              </RippleTypography>
              <Box pt="12px">
                <RippleButton size="md" width="initial" onClick={handleLaunchApp}>
                  {t('sos-package:downloadMobile.launchApp')}
                </RippleButton>
              </Box>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>3</NumberTag>
            <Flex flexDirection="column" pr="16px" pt="6px">
              <RippleTypography variant="body02" color="dark.90">
                Request support when you need support
              </RippleTypography>

              <RippleTypography variant="body02" color="neutral.100">
                With SOS Call, you can create a support session right from the app. Fill in your name and wait for the technician to connect
              </RippleTypography>
            </Flex>
          </ListItem>
        </List>
      </Box>

      <Box>
        <RippleDivider my="2em" />
        <RippleTypography variant="body02" color="dark.90">
          {t('sos-package:download.note.line1')}
        </RippleTypography>
        <br />
        <RippleTypography variant="body02" color="dark.90">
          {t('sos-package:download.note.line2')}
        </RippleTypography>
      </Box>
    </Card>
  );
};

export default SosCallAndroidLauncher;
