import { Box, Flex, List, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

import Card from '@/components/BasicCard';
import * as Logo from '@/components/Logo';
import { RippleDivider, RippleMacNoBorder, RippleOldWindowsNoBorder, RippleTypography } from '@/design';
import { NumberTag } from '@/modules/Welcome/NumberTag';

export type SosCallDownloadCardProps = {
  onWindowsDownload: () => void;
  onMacDownload: () => void;
};

const SosCallDownloadCard = ({ onWindowsDownload, onMacDownload }: SosCallDownloadCardProps) => {
  const { t } = useTranslation();

  return (
    <Card maxWidth="640px" mb="64px">
      <Logo.Splashtop />
      <Box mt="1.5em" mb="1em">
        <Logo.SupportApp />
      </Box>
      <RippleTypography variant="heading04" py="16px">
        {t('sos-call:downloadApp.title')}
      </RippleTypography>
      <RippleTypography variant="body01">{t('sos-call:downloadApp.subtitle')}</RippleTypography>
      <Box width="432px">
        <List as="ol" styleType="none" pt="48px" spacing="24px">
          <ListItem fontSize="14px" display="flex">
            <NumberTag>1</NumberTag>
            <Flex flexDirection="column" px="16px" pt="6px">
              <RippleTypography variant="body02">{t('sos-package:download.step1.line2')}</RippleTypography>
              <Flex pt="1em" align="center">
                <Box pr="8px">
                  <RippleOldWindowsNoBorder color="blue.100" />
                </Box>
                <RippleTypography
                  variant="body02"
                  data-testid="service-desk__download__windows"
                  color="blue.100"
                  cursor="pointer"
                  onClick={onWindowsDownload}
                >
                  {t('sos-package:download.step1.windowsDownload')}
                </RippleTypography>
                <Box pr="8px" pl="32px">
                  <RippleMacNoBorder color="blue.100" />
                </Box>
                <RippleTypography
                  variant="body02"
                  data-testid="service-desk__download__mac"
                  color="blue.100"
                  cursor="pointer"
                  onClick={onMacDownload}
                >
                  {t('sos-package:download.step1.macDownload')}
                </RippleTypography>
              </Flex>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>2</NumberTag>
            <Flex flexDirection="column" px="16px" pt="6px">
              <RippleTypography variant="body02">{t('sos-call:downloadApp.step2.line1')}</RippleTypography>
              <RippleTypography variant="body02" color="dark.40">
                {t('sos-package:download.step2.line2')}
              </RippleTypography>
            </Flex>
          </ListItem>
          <ListItem fontSize="14px" display="flex">
            <NumberTag>3</NumberTag>
            <Flex flexDirection="column" px="16px" pt="6px">
              <RippleTypography variant="body02">{t('sos-call:downloadApp.step3.line1')}</RippleTypography>
              <RippleTypography variant="body02" color="dark.40">
                {t('sos-call:downloadApp.step3.line2')}
              </RippleTypography>
            </Flex>
          </ListItem>
        </List>
      </Box>

      <Box>
        <RippleDivider my="2em" />
        <RippleTypography variant="body02">{t('sos-package:download.note.line1')}</RippleTypography>
        <br />
        <RippleTypography variant="body02">{t('sos-package:download.note.line2')}</RippleTypography>
      </Box>
    </Card>
  );
};

export default SosCallDownloadCard;
