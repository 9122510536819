import { checkResponse, pureInstance } from '@/services/utils';

import { sosCallLaunchURLDetailSchema } from './type';

const getUrl = (packageCode: string) => `/api/web/v1/teams/service_desk/call/${packageCode}/detail`;

export function execute(packageCode: string) {
  return checkResponse(pureInstance.get(getUrl(packageCode)), sosCallLaunchURLDetailSchema);
}

export const getSosCallLaunchURLDetailService = {
  getUrl,
  execute,
};
