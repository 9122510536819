import React, { useCallback, useEffect } from 'react';

import NotAvailable from '@/modules/Customization/Download/NotAvailable';
import { downloadModel } from '@/modules/Customization/Download/machine';
import { Category } from '@/modules/Customization/Download/types';
import { useSosCallDownloadMachine } from '@/modules/ServiceDesk/Download/machine';
import getOS from '@/utils/getOS';

import SosCallDownloadCard from './SosCallDownloadCard';

type SosCallDownloadProps = {
  packageCode: string;
};

const SosCallDownload = ({ packageCode }: SosCallDownloadProps) => {
  const [state, send] = useSosCallDownloadMachine(packageCode);

  const { downloadLink, downloadName } = state.context;

  const handleDownload = useCallback(
    (category: Category) => () => {
      send(downloadModel.events.DOWNLOAD(category));
    },
    [send],
  );

  useEffect(() => {
    const os = getOS();
    if (os === 'Mac') {
      handleDownload('mac')();
    } else {
      handleDownload('win')();
    }
  }, [handleDownload]);

  useEffect(() => {
    if (downloadLink) {
      const rawFileName = downloadLink.split('/').pop() ?? '';
      const fileExtension = rawFileName?.split('.')?.pop();
      const customFileName = downloadName && fileExtension ? `${downloadName}.${fileExtension}` : null;
      const downloadFileName = customFileName ? customFileName : rawFileName;

      window.URL = window.URL || window.webkitURL;
      const xhr = new XMLHttpRequest();
      const downloadDOM = document.createElement('a');

      xhr.open('GET', downloadLink, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const file = new Blob([xhr.response], { type: 'application/octet-stream' });
        downloadDOM.href = window.URL.createObjectURL(file);
        downloadDOM.download = downloadFileName;
        downloadDOM.click();
      };
      xhr.send();

      return () => {
        xhr.abort();
      };
    }
  }, [downloadLink, downloadName]);

  if (state.matches('blocking')) {
    return <NotAvailable />;
  }

  return (
    <>
      <SosCallDownloadCard onWindowsDownload={handleDownload('win')} onMacDownload={handleDownload('mac')} />
    </>
  );
};

export default SosCallDownload;
